import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonSpinner } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import './LoginCard.scss';
import {
  fetchBalance,
  loginSuccess,
  loginFailed,
  requestStart,
  requestEnd,
} from '../../store';
import { RootState } from '../../models/RootState';
import { IonLabel } from '@ionic/react';
import API from '../../api';
import ForgotPwdForm from '../ForgotPassword/ForgotPassword';
import Modal from '../Modal/Modal';
import API_V2 from '../../api-services/svls-api';
import { BRAND_DOMAIN, BRAND_NAME } from '../../constants/Branding';

type StoreProps = {
  loginSuccess: Function;
  loginFailed: Function;
  requestStart: Function;
  requestEnd: Function;
  fetchBalance: Function;
};

type LoginProps = StoreProps & {
  errorMsg: string;
  loading: boolean;
  loggedIn: boolean;
  redirectUrl?: string;
};

const LoginForm: React.FC<LoginProps> = (props) => {
  const {
    errorMsg,
    loading,
    loggedIn,
    loginSuccess,
    loginFailed,
    requestStart,
    requestEnd,
    fetchBalance,
    redirectUrl,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [useAuthenticator, setUseAuthenticator] = useState<boolean>(false);
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);
  const [demoLoginEnabled, setDemoLoginEnabled] = useState<boolean>(false);
  const [signUpLink, setSignUpLink] = useState<string>(null);

  let history = useHistory();

  // const forgotPwdHandler = () => {
  //   setShowForgotPwdModal(true);
  // };
  useEffect(() => {
    isSignUpEnabled();
  },[])

  const isSignUpEnabled = () => {
    if(window.location.hostname.includes('sarkar11.com')) {
      setSignUpLink('https://wa.link/annakrishna');
      return;
    }

    if(window.location.hostname.includes('diamond6ball.com')) {
      setSignUpLink('https://wa.link/6ball');
      return;
    }
      setSignUpLink(null);
  };

  const { search } = useLocation();
  const authToken = new URLSearchParams(search).get('authToken');
  useEffect(() => {
    if (authToken) {
      const username = authToken?.split('.')?.[1];
      let uname = JSON.parse(window?.atob(username)).sub;
      sessionStorage.setItem('username', uname);
      sessionStorage.setItem('jwt_token', authToken);
      loginSuccess({ jwtToken: authToken });
      history.push('/home');
      fetchBalance();
    }
  }, [authToken]);

  const formik = useFormik({
    initialValues: { username: '', password: '', code: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string(),
      code: Yup.string(),
    }),

    onSubmit: async (values) => {
      if (values.code) {
        requestStart();
        const validateResponse = await API.post('/mfa/validate/key', {
          username: values.username.toLowerCase(),
          uuid: '',
          code: values.code,
        });
        if (validateResponse.data.success) {
          sessionStorage.setItem('username', values.username.toLowerCase());
          sessionStorage.setItem('jwt_token', loginResponse.jwtToken);
          setLoginResponse({ jwtToken: loginResponse.jwtToken });
          loginSuccess(loginResponse);
          let claim = loginResponse.jwtToken.split('.')[1];
          let permission = JSON.parse(window.atob(claim)).perm;
          let status = JSON.parse(window.atob(claim)).sts;
          if (status === 2) {
            history.replace('/terms-and-conditions');
          } else if (status === 4) {
            history.replace('/reset-password');
          } else if ((permission & 2) !== 0) {
            history.replace('/platform_admin/house');
          }
          if (redirectUrl) {
            history.push(redirectUrl);
          }
        } else {
          loginFailed('Incorrect code');
        }
      } else {
        const uuid = uniqueGuid();
        requestStart();
        try {
          const loginRequest = {
            username: values.username.toLowerCase(),
            password: values.password.trim(),
          };

          const response = await API_V2.post(
            '/account/v2/login',
            loginRequest,
            {
              responseType: 'text',
            }
          );
          requestEnd();

          sessionStorage.setItem('username', values.username.toLowerCase());
          sessionStorage.setItem('jwt_token', response.data);
          localStorage.removeItem(
            `multiMarket_${values?.username?.toLowerCase()}`
          );
          setLoginResponse({ jwtToken: response.data });
          loginSuccess({ jwtToken: response.data });

          let claim = response.data.split('.')[1];
          let permission = JSON.parse(window.atob(claim)).perm;
          let status = JSON.parse(window.atob(claim)).sts;

          if (status === 2) {
            history.replace('/terms-and-conditions');
          } else if (status === 4) {
            history.replace('/reset-password');
          } else if ((permission & 2) !== 0) {
            history.replace('/platform_admin/house');
          }

          if (redirectUrl) {
            history.push(redirectUrl);
          } else {
            history.push('/home');
          }
        } catch (err) {
          loginFailed(err?.response?.data?.message);
        }
      }
    },
  });

  const handleDemoLogin = async () => {
    setDemoLoading(true);
    setLoadLogin(false);
    requestStart();
    try {
      const response = await API_V2.post('/account/v2/login-demo');
      setDemoLoading(false);
      if (response.status == 200) {
        requestEnd();

        let claim = response.data.split('.')[1];
        sessionStorage.setItem(
          'username',
          JSON.parse(window.atob(response.data.split('.')[1])).sub
        );
        sessionStorage.setItem('jwt_token', response.data);
        localStorage.removeItem(
          `multiMarket_${JSON.parse(
            window.atob(response.data.split('.')[1])
          ).sub.toLowerCase()}`
        );

        setLoginResponse({ jwtToken: response.data });
        loginSuccess({ jwtToken: response.data });

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          history.push('/home');
        }
      }
    } catch (err) {
      setDemoLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    isDemoLoginEnabled();
  }, []);

  const isDemoLoginEnabled = async () => {
    try {
      const response = await API_V2.get(`/account/v2/books/${BRAND_DOMAIN}`);
      if (response.status === 200) {
        setDemoLoginEnabled(response.data.demo_user_enabled);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uniqueGuid = (): string => {
    const id = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      id() +
      id() +
      '-' +
      id() +
      '-' +
      id() +
      '-' +
      id() +
      '-' +
      id() +
      id() +
      id()
    );
  };

  useEffect(() => {
    if (loggedIn) {
      history.push('/home');
      fetchBalance();
    }
  }, [fetchBalance, loggedIn]);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="login-form-ctn"
      autoComplete="off"
    >
      <div className="form-title web-view">
        LOGIN <i className="fas fa-hand-point-down"></i>
      </div>
      <div>
        {formik.touched.username && formik.errors.username ? (
          <div className="alert-danger">{formik.errors.username}</div>
        ) : null}

        {formik.touched.password && formik.errors.password ? (
          <div className="alert-danger">{formik.errors.password}</div>
        ) : null}
        {errorMsg !== '' ? (
          <div className="alert-danger">{errorMsg}</div>
        ) : null}
      </div>

      <div className="input-control usr-input">
        <TextField
          className="login-input-field"
          placeholder="User Name"
          type="text"
          name="username"
          variant="outlined"
          error={
            formik.touched.username && formik.errors.username ? true : false
          }
          // helperText={
          //     formik.touched.username && formik.errors.username
          //         ? formik.errors.username
          //         : null
          // }
          {...formik.getFieldProps('username')}
        />
      </div>

      <div className="pwd-input input-control">
        <FormControl
          className="pwd-field"
          variant="outlined"
          error={
            formik.touched.password && formik.errors.password ? true : false
          }
        >
          <OutlinedInput
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            {...formik.getFieldProps('password')}
            // endAdornment={
            //   <InputAdornment position="end" className="input-icon">
            //     <IconButton
            //       aria-label="toggle password visibility"
            //       onClick={showPasswordClickHandler}
            //       onMouseDown={showPasswordClickHandler}
            //     >
            //       {showPassword ? <Visibility /> : <VisibilityOff />}
            //     </IconButton>
            //   </InputAdornment>
            // }
          />
        </FormControl>
      </div>

      {useAuthenticator ? (
        <span className="code-input">
          <IonLabel className="input-label">Code</IonLabel>
          <TextField
            className="login-input-field user-name"
            type="text"
            name="username"
            variant="outlined"
            {...formik.getFieldProps('code')}
          />
        </span>
      ) : null}
      <div className="login-and-demo-login">
        <Button
          className={
            demoLoginEnabled
              ? 'login-form-btn'
              : 'login-form-btn-without-demologin'
          }
          endIcon={
            loading && loadLogin ? <IonSpinner name="lines-small" /> : ''
          }
          type="submit"
        >
          <span className="ml-5">Login</span>
          {!(loading && loadLogin) ? (
            <i className="fas fa-sign-in-alt" />
          ) : null}
        </Button>
        {demoLoginEnabled ? (
          <Button
            className="login-form-btn"
            endIcon={demoLoading ? <IonSpinner name="lines-small" /> : ''}
            onClick={handleDemoLogin}
            variant="contained"
          >
            <span className="ml-5">Demo Login</span>
            {!demoLoading ? <i className="fas fa-sign-in-alt" /> : null}
          </Button>
        ) : null}
      </div>
      {signUpLink? (
        <div className="sign-up-btn">
          <a
            href={signUpLink}
            target="_blank"
            className="ml-5"
          >
            <Button className="login-form-btn-without-demologin">
              Sign Up
            </Button>
          </a>
        </div>
      ) : null}

      <Modal
        open={showForgotPwdModal}
        closeHandler={() => setShowForgotPwdModal(false)}
        title="Forgot Password"
        size="xs"
      >
        <ForgotPwdForm closeHandler={() => setShowForgotPwdModal(false)} />
      </Modal>
    </form>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    errorMsg: state.auth.loginError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
    loginFailed: (err: string) => dispatch(loginFailed(err)),
    requestStart: () => dispatch(requestStart()),
    requestEnd: () => dispatch(requestEnd()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
