import API from '../../api';
import {
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_FAILED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  AUTH_REQUEST_START,
  AUTH_REQUEST_END,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  MLOBBY_SHOW,
} from './authActionTypes';
import V2_API from '../../api-services/svls-api';
import { createBrowserHistory } from 'history';
import SVLS_API from '../../api-services/svls-api';
import { BRAND_DOMAIN } from '../../constants/Branding';
// import axios from 'axios';

declare const sessionStorage: any;

export const requestStart = () => {
  return {
    type: AUTH_REQUEST_START,
  };
};

export const requestEnd = () => {
  return {
    type: AUTH_REQUEST_END,
  };
};

export const loginSuccess = (response) => {
  return {
    type: LOGIN_SUCCESS,
    payload: response,
  };
};

export const loginFailed = (err: string) => {
  return {
    type: LOGIN_FAILED,
    payload: err,
  };
};

const logoutSuccess = () => {
  return {
    type: LOG_OUT_SUCCESS,
  };
};

const logoutFailed = (err: string) => {
  return {
    type: LOG_OUT,
    payload: err,
  };
};

export const getRoleFromToken = (): string => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).role;
  }
  return null;
};

export const getPermissionFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).perm;
  }
  return null;
};

export const getCurrencyTypeFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).cur;
  }
  return 0;
};

export const getSapTokenFromToken = () => {
  var sapToken = sessionStorage.getItem('sap_token');
  return sapToken ? sapToken : null;
};

export const getHouseIdFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).hid;
  }
  return null;
};

export const getSportsBookFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).org;
  }
  return null;
};

export const getParentIdFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).pid;
  }
  return null;
};

export const getAccountIdFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).aid;
  }
  return null;
};

export const getAccountPathFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).path;
  }
  return null;
};

export const login = (username: string, password: string, code: string) => {
  return async (dispatch: Function) => {
    const uuid = uniqueGuid();
    dispatch(requestStart());
    try {
      let response;
      if (code) {
        response = await API.post('/mfa/validate/key', {
          username,
          code,
          uuid,
        });
      } else {
        const loginRequest = {
          username,
          password,
          uuid,
        };
        response = await SVLS_API.post('/login', loginRequest);
      }
      sessionStorage.setItem('username', username);
      sessionStorage.setItem('jwt_token', response.data.jwtToken);
      // sessionStorage.setItem('bg_token', response.data.bgToken);
      // sessionStorage.setItem('bc_token', response.data.bcToken);
      // sessionStorage.setItem('wacs_token', response.wacsToken);
      // sessionStorage.setItem('dc_token', response.dcToken);
      dispatch(loginSuccess(response.data));
      const history = createBrowserHistory({ forceRefresh: true });
      let claim = response.data.jwtToken.split('.')[1];
      let permission = JSON.parse(window.atob(claim)).perm;
      let role = JSON.parse(window.atob(claim)).role;
      let status = JSON.parse(window.atob(claim)).sts;
      if (status === 2) {
        history.replace('/terms-and-conditions');
      } else if (status === 4) {
        history.replace('/reset-password');
      } else if ((permission & 2) !== 0) {
        history.replace('/platform_admin/house');
      } else if (role !== 'User') {
        history.replace('/admin');
      }
    } catch (err) {
      dispatch(loginFailed(err.response.data.message));
    }
  };
};

export const signup = (username: string, password: string) => {
  return async (dispatch: Function) => {
    dispatch(requestStart());
    try {
      const response = await API.post('/user', {
        username,
        password,
      });
      dispatch(loginSuccess(response.data));
      sessionStorage.setItem('username', username);
      // sessionStorage.setItem('bg_token', response.data.bgToken);
      sessionStorage.setItem('jwt_token', response.data.jwtToken);
      // sessionStorage.setItem('wacs_token', response.data.wacsToken);
      // sessionStorage.setItem('dc_token', response.data.dcToken);
    } catch (err) {
      dispatch(loginFailed(err.message));
    }
  };
};

export const logout = () => {
  const token = sessionStorage.getItem('jwt_token');
  return async (dispatch: Function) => {
    try {
      await V2_API.post('/account/v2/logout', null, {
        headers: {
          Authorization: token,
        },
      });

      const username = sessionStorage.getItem('username') ?? '';
      localStorage.removeItem(`multiMarket_${username}`);
      sessionStorage.clear();
      dispatch(logoutSuccess());
      window.open(`https://${BRAND_DOMAIN}/login`, '_self');
    } catch (err) {
      sessionStorage.clear();
      dispatch(logoutFailed(err.message));
    }
  };
};

const fetchBalanceSuccess = (balance: number) => {
  return {
    type: FETCH_BALANCE_SUCCESS,
    payload: balance,
  };
};

const fetchBalanceFailed = () => {
  return {
    type: FETCH_BALANCE_FAILED,
  };
};

const uniqueGuid = (): string => {
  const id = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    id() +
    id() +
    '-' +
    id() +
    '-' +
    id() +
    '-' +
    id() +
    '-' +
    id() +
    id() +
    id()
  );
};

export const fetchBalance = () => {
  return async (dispatch: Function) => {
    try {
      const response = await API.get('/user/balance-summary', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      dispatch(fetchBalanceSuccess(response.data));
    } catch (err) {
      // dispatch(fetchBalanceFailed());
      if (err.response && err.response.status === 401) {
        const token = sessionStorage.getItem('jwt_token');
        if (token) {
          dispatch(logout());
        }
      }
    }
  };
};

export const enableMlobby = (isMolobby: boolean) => {
  return {
    type: MLOBBY_SHOW,
    payload: isMolobby,
  };
};
