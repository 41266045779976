import {BACKEND_DOMAIN , BRAND_DOMAIN } from '../../constants/Branding';

export const DevUrlsMap = {
  REACT_APP_REST_API_URL: `https://dev-user-api.sky11.com/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://dev-user-api.sky11.com`,
  BINARY_WEBSOCKET_URL: `https://user-api.sky11.com/hypex-ws`,
  REACT_APP_REST_REPORTING_API_URL: `https://dev-reporting.sky11.com`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://dev-reporting.sky11.com/push-notifications`,
};

export const StageUrlsMap = {
  REACT_APP_REST_API_URL: `https://stage-user-api.${BRAND_DOMAIN}/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://stage-user-api.${BRAND_DOMAIN}`,
  BINARY_WEBSOCKET_URL: `https://user-api.${BRAND_DOMAIN}/hypex-ws`,
  REACT_APP_REST_REPORTING_API_URL: `https://stage-reporting.${BRAND_DOMAIN}`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://stage-reporting.${BRAND_DOMAIN}/push-notifications`,
};

export const ProdUrlsMap = {
  REACT_APP_REST_API_URL: `https://api.${BACKEND_DOMAIN}/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://api.${BACKEND_DOMAIN}`,
  BINARY_WEBSOCKET_URL: `https://user-api.${BACKEND_DOMAIN}/hypex-ws`,
  REACT_APP_REST_REPORTING_API_URL: `https://reporting.${BACKEND_DOMAIN}`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://reporting.${BACKEND_DOMAIN}/push-notifications`,
};

export const EnvUrlsMap = {
  development: DevUrlsMap,
  stage: StageUrlsMap,
  production: ProdUrlsMap,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
